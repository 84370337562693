<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      max-height="80vh"
      :src="require('@/assets/background/4.png')"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <!-- <base-subheading
            weight="regular"
            title="WE LOVE MAKING THINGS SIMPLE AND AMAZING"
          /> -->

          <base-heading
            size="text-h2"
            title="IMPROVE YOUR POSITION"
            weight="medium"
          />

          <base-body>
            <p
              class="white--text"
            >
              Our mission is to help you improve your knowledge and understanding of position. We achieve this by providing you with scientific processing and analysis, continuous monitoring, education, and knowledge transfer.
            </p>
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <base-btn
              @click="onClick($event, '#services')"
            >
              Services
            </base-btn>

            <span class="font-weight-bold ml-6 mr-4 my-4">or</span>

            <base-btn
              @click="onClick($event, '#features')"
            >
              Products
            </base-btn>

            <!-- <base-btn
              :ripple="false"
              class="pa-1"
              height="auto"
              text
              to="Products"
            >
              Products
            </base-btn> -->
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '80vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },

    methods: {
      onClick (e, item) {
        e.stopPropagation()

        this.$vuetify.goTo(item)
      },
    },
  }
</script>
